import Layout from "../components/Layout";
import Seo from "../components/seo";

export default () => {
  return (
    <Layout>
      <Seo title="DocuZen Newsroom: The Latest News &amp; Announcements" />

      <div className="container text-center mb-5">
        <div className="row">
          <h1>Newsroom</h1>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2"></div>
          <h5 className="text-center my-4">No newsroom articles yet</h5>
        </div>
      </div>
    </Layout>
  );
};
